import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Secondary from '@/layout/secondary.vue'
import index from './modules'
import shop from './modules/shop'
import order from './modules/order'
import setting from './modules/setting'
import channel from './modules/channel'
import model from './modules/model'
Vue.use(VueRouter)

export const asyncRoutes: Array<RouteConfig> = [
  ...index, // 首页
  ...shop, // 店铺
  ...model,
  ...order,
  ...channel,
  ...setting // 设置
]

const constantRoutes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: '/error/404'
  },
  {
    path: '/account',
    name: 'account',
    component: Secondary,
    redirect: '/account/login',
    children: [
      {
        path: '/account/login',
        name: 'login',
        meta: {
          title: '登录',
          parentPath: '/account'
        },
        component: () => import('@/views/account/login.vue')
      }
    ]
  },
  {
    path: '/error',
    name: 'error',
    component: Secondary,
    redirect: '/error/404',
    children: [
      {
        path: '/error/404',
        name: 'error_404',
        meta: {
          title: '404',
          parentPath: '/error'
        },
        component: () => import('@/views/error/404.vue')
      },
      {
        path: '/error/500',
        name: 'error_500',
        meta: {
          title: '500',
          parentPath: '/error'
        },
        component: () => import('@/views/error/500.vue')
      }
    ]
  },
  {
    path: '/UpgradeAfter/run',
    name: 'UpgradeAfter',
    component: Secondary,
    redirect: '/UpgradeAfter/run',
    children: [
      {
        path: '/UpgradeAfter/run',
        name: 'UpgradeAfter',
        meta: {
          title: '更新后执行内容',
          parentPath: '/UpgradeAfter'
        },
        component: () => import('@/views/setting/UpgradeAfter/index.vue')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [...asyncRoutes, ...constantRoutes]
})

export default router
